// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-effortlessly-blog-details-js": () => import("./../../../src/pages/effortlessly-blog-details.js" /* webpackChunkName: "component---src-pages-effortlessly-blog-details-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-term-condition-js": () => import("./../../../src/pages/term-condition.js" /* webpackChunkName: "component---src-pages-term-condition-js" */),
  "component---src-pages-translatecode-blog-details-js": () => import("./../../../src/pages/translatecode-blog-details.js" /* webpackChunkName: "component---src-pages-translatecode-blog-details-js" */),
  "component---src-pages-writecode-blog-details-js": () => import("./../../../src/pages/writecode-blog-details.js" /* webpackChunkName: "component---src-pages-writecode-blog-details-js" */)
}

